#teacher-question-generator {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px #4444 solid;
}

.main-title {
  text-align: center;
  margin-bottom: 20px;
}

.form {
  /* background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}
.question-form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Space between inputs */
}

.question-form .ant-form-item {
  flex: 1 1 45%; /* Make each input take up about half of the row */
  min-width: 300px; /* Minimum width for responsiveness */
}
.next-btn {
  width: 100%;
}

.layout-step {
  text-align: center;
  margin-top: 20px;
}

.generate-btn {
  margin-top: 20px;
  background-color: #1890ff;
  color: white;
}

.generated-exam {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.exam-content {
  margin-top: 20px;
  padding: 10px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.regenerate-btn,
.print-btn {
  margin-right: 10px;
  margin-top: 20px;
}
/* //////////////////////// */
.generated-exam {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.exam-header {
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.exam-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.exam-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.exam-info p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  width: 48%; /* For two-column layout */
}

.exam-content {
  margin-top: 20px;
  padding: 15px;
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.regenerate-btn,
.print-btn {
  margin-right: 10px;
  margin-top: 20px;
}
.enhanced-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.enhanced-form .ant-form-item {
  margin-bottom: 16px;
}

/* Responsive styling */
@media (max-width: 768px) {
  #teacher-question-generator {
    padding: 15px;
  }

  .form,
  .generated-exam {
    padding: 15px;
  }

  .next-btn,
  .generate-btn {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .enhanced-form .ant-row {
    flex-direction: column;
  }
}
