#NoticeBoard .category {
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  padding: 0.4rem 0.6rem;
  border-radius: 15px;
  margin-bottom: 0.5px;
}

#NoticeBoard .meta {
  font-size: 1.1rem;
  font-weight: bold;
}
#NoticeBoard .category-color-1 {
  background: #d40dfc;
}
#NoticeBoard .category-color-2 {
  background: #fbd540;
}
#NoticeBoard .category-color-3 {
  background: #f939a1;
}

#NoticeBoard .category-container {
  max-height: 500px;
  overflow-y: scroll;
}
#NoticeBoard .category-container::-webkit-scrollbar {
  width: 0.5rem;
}
#NoticeBoard .category-container::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 5px;
}

#NoticeBoard .category-container::-webkit-scrollbar-thumb {
  background: #3751fe;
  border-radius: 5px;
}

@media (max-width: 800px) {
  #NoticeBoard .category {
    display: inline-block;
    color: #fff;
    font-size: 0.8rem;
    padding: 0.2rem 0.4rem;
    border-radius: 15px;
    margin-bottom: 0.5px;
  }

  #NoticeBoard .meta {
    font-size: 1rem;
    font-weight: bold;
  }
}
