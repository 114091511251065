/* reset */
:root {
  --primary-color: #ebeced;
  --secondary-color: #1c344e;
  --primary-color-1: #0053a6;
  --secondary-color-1: #1c2333;
  --secondary-bg: #0e1525;
  --light-color: #fff;
  --dark-color: #1c2333;
  --dark-color-1: #2b3245;
  --dark-color-2: #3c445c;
  --light-blue: #059bff;

  --blue-color: #3a85f9;
  --red-color: #ff4069;
  --category-color-1: #40dfcd;
  --category-color-2: #fbd540;
  --category-color-3: #f939a1;

  --max-width: 1100px;

  --border-color-1: #c0c3c4;
  --border-radius-1: 5px;
  --gap-8: 8px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Gotham SSm", -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", Arial, sans-serif;

  line-height: 1.5;
  font-size: 16px;
  background: var(--primary-color);
}

a {
  color: var(--secondary-color);
  text-decoration: none;
}
ul {
  list-style: none;
}
p {
  margin: 0.5rem 0;
  line-height: 1.7;
}
img {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.55rem;
  line-height: 1.3;
  color: var(--secondary-color);
}
i {
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.btn {
  cursor: pointer;
  display: inline-block;
  color: var(--secondary-color);
  background: var(--light-color);
  padding: 0.3rem 1rem;
}
.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--light-color);
  padding: 30px;
}

.layout-content {
  margin-left: 200px;
}

.custom-sider {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  transition: width 0.3s;
  overflow-y: scroll;
}

.custom-sider .logo {
  height: 64px;
  margin: 16px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.custom-sider .logo .icon-taxi {
  margin-right: 8px;
}

@media (max-width: 1000px) {
  .layout-content {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .custom-sider {
    position: fixed;
  }
}
