#Subject h2 {
  margin-left: 20px;
}
.subject-page {
  padding: 20px;
  background-color: #f9f9f9;
}

.ant-select {
  width: 200px;
}

.ant-table {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: bold;
}

.ant-btn-link {
  color: #1890ff;
}

.ant-btn-link:hover {
  color: #40a9ff;
}

.ant-btn-link[danger] {
  color: #ff4d4f;
}

.ant-btn-link[danger]:hover {
  color: #ff7875;
}
