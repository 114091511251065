.exam-history-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.exam-history-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.filter-section {
  margin-bottom: 20px;
  text-align: center;
}

.exam-history-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.exam-history-header {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.exam-content {
  background-color: #fafafa;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.exam-history-header p {
  margin: 5px 0;
  font-size: 16px;
}
/* ExamModal.css */

/* General Styling for the Modal */
.exam-details-modal {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.exam-header {
  margin-bottom: 20px;
}

.exam-info-title {
  color: #1890ff; /* Primary color for headings */
  margin-bottom: 10px;
}

.exam-info-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;
}

.exam-questions-section {
  margin-top: 20px;
}

.questions-title {
  color: #1890ff; /* Primary color for headings */
  margin-bottom: 10px;
}

.question-item {
  padding: 10px 0;
}

.question-text,
.answer-text {
  margin-bottom: 5px;
}

/* Print-Specific Styling */
@media print {
  .exam-details-modal {
    padding: 0;
    background: #ffffff;
  }

  .exam-info-section {
    grid-template-columns: 1fr;
  }

  .exam-questions-section {
    page-break-inside: avoid;
  }

  .questions-title,
  .exam-info-title {
    color: #000000;
  }

  .question-item {
    page-break-inside: avoid;
    padding: 0;
    border-bottom: 1px solid #ddd;
  }
}

.print-btn {
  margin-top: 20px;
}
