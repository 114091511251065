#card h2 {
  color: var(--light-color);
}

#card .like-i {
  font-size: 35px;
}
#card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
