.ai-training-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.chat-window {
  height: 400px;
  overflow-y: auto;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
}

.chat-input-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-input {
  flex: 1;
}

.send-button {
  background-color: #1890ff;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-message {
  background-color: #e6f7ff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
}

.ai-message {
  background-color: #f6ffed;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
}

.uploading-spinner {
  display: block;
  text-align: center;
  margin-top: 10px;
}
