.feedback-analysis-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f5ff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.feedback-analysis-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.feedback-analysis-container .filter-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.feedback-analysis-container .feedback-table {
  margin-top: 20px;
}

.feedback-analysis-container .ant-table-thead > tr > th {
  background-color: #e6f7ff;
  font-weight: bold;
  text-align: center;
}

.feedback-analysis-container .ant-table-tbody > tr > td {
  text-align: center;
}

.feedback-analysis-container .questions-container {
  margin-top: 10px;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 4px;
}

.feedback-analysis-container .question-answer p {
  margin: 5px 0;
}
