#chart .chart-title h3 {
  padding: 0.5rem 1rem;
}

#chart .chart-content {
  padding: 1rem;
  margin: auto;
}

#chart .card {
  background: none;
  padding: 0;
}

#chart .card .sex-count {
  font-weight: 400;
  color: #1c344e;
}
#chart .card .male-student {
  color: var(--red-color);
  padding: 40px;
}
#chart .card .female-student {
  color: var(--light-blue);
}

@media (max-width: 800px) {
  #chart .chart-content {
    max-width: 250px;
    padding: 2px;
    margin: 0;
  }
  #chart .card .sex-count {
    font-size: smaller;
  }
  #chart .card h1 {
    font-size: larger;
    padding-left: 15px;
  }

  #chart .card .description {
    margin: auto;
    font-size: larger;
  }
}
