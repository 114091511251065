#Auth .text-primary {
  color: var(--primary-color);
}

#Auth #main-nav {
  position: sticky;
  top: 0;
  left: 0;
  height: 60px;
  background: var(--dark-color);
  z-index: 2;
}

#Auth #main-nav .main-nav-container {
  /* display: grid;
  grid-template-columns: auto 1fr auto;
  padding-left: 10px;
  padding-right: 15px;
  grid-auto-flow: column;
  grid-column-gap: var(--gap-8);
  height: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
}

#Auth #main-nav .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 50px;
}
#Auth #main-nav .logo {
  font-size: larger;
  font-family: "Tektur", sans-serif;
  font-weight: 900;
  margin-bottom: 0;
}

#Auth #main-nav .main-nav-container .logo-container img {
  max-width: 30px;
  margin-right: 5px;
}

#Auth #main-nav .main-menu ul {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
}

#Auth #main-nav .auth-container ul {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
}

#Auth #main-nav li {
  margin: 0 5px;
}

#Auth #main-nav .menu-btn-container {
  display: none;
}

#Auth #main-nav .side-nav {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--dark-color);
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 5px;
  padding: 10px 0;
  display: none;
}

#Auth #main-nav .side-nav li {
  display: flex;
  padding: 10px;
  text-align: center;
}

/* showcase */
#showcase {
  color: var(--light-color);
  background: var(--secondary-bg);
  padding: 2rem;
  position: relative;
}

#Auth #showcase:before {
  content: "";
  background: url("../../../public/images/showcase.jpg") no-repeat center
    center/cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.06;
}

#showcase .showcase-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

#showcase .showcase-content {
  z-index: 1;
  margin-left: 50px;
}

#showcase .showcase-content h1 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}
#showcase .showcase-content p {
  margin-bottom: 1rem;
  color: #fff;
  margin: 0.5rem 0;
  line-height: 1.7;
}
#showcase .showcase-content .btn {
  padding: 10px;
}

@media (max-width: 800px) {
  #showcase .showcase-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    justify-content: center;
    align-items: center;
    height: 82vh;
  }
  #showcase {
    color: var(--light-color);
    background: var(--dark-color);
    padding: 2rem;
    position: relative;
    height: 900px;
  }
  #showcase .showcase-content {
    z-index: 1;
    margin-left: 0px;
  }
}
