#DashboardMain .container {
  display: grid;
  grid-template-columns: 200px 8fr;
  overflow: hidden;
}
#DashboardMain .dashboard-summary-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;
}

/* dashboard-card */

#DashboardMain .dashboard-card {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: var(--border-radius-1);
}

#DashboardMain .dashboard-container {
  display: grid;
  grid-template-columns: 0.8fr 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
}

#DashboardMain .main-content {
  padding: 20px;
  width: 100%;
  height: 100%;
}

#DashboardMain .main-content > :first-child {
  padding: 20px;
}

/* table */
#DashboardMain .table-container {
  padding: 1rem;
}

.custom-card > * {
  color: var(--light-color);
}
.custom-card div {
  color: var(--light-color);
}

.custom-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  opacity: 0.05;
  pointer-events: none;
}

@media (max-width: 800px) {
  #DashboardMain .dashboard-summary-container {
    max-width: 600px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0.5rem;
  }
  #DashboardMain .dashboard-container {
    max-width: 600px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0.5rem;
  }

  #DashboardMain .dashboard-card {
    padding: 0.2rem;
    background-color: #ffffff;
    border-radius: var(--border-radius-1);
  }

  #DashboardMain .dashboard-card .dashboard-chart-card {
    padding: 20px;
  }
}
