#StudentRegistration .confirm button {
  margin-right: 20px;
  padding: 13px 25px;
}

#StudentRegistration .close-title {
  width: 100%;
  height: 30px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 5px;
  font-size: small;
  margin-bottom: 15px;
  font-weight: 300;
}

#StudentRegistration .step-container {
  padding: 0px 100px;
  margin-bottom: 20px;
}

#StudentRegistration .steps-action {
  float: right;
}
