#PaymentPage .ant-card {
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 1000px;
  max-height: fit-content;
  overflow-y: scroll;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  justify-content: start !important;
  width: 350px;
}

#PaymentPage #payment-showcase {
  margin-top: 50px;
}

/* Hide scrollbar for WebKit browsers */
#PaymentPage .ant-card::-webkit-scrollbar {
  display: none;
}

#PaymentPage .ant-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.2);
}

#PaymentPage .ant-card h2 {
  font-size: 2.5rem;
  color: #007bff;
  margin: 0;
}

#PaymentPage .ant-card p {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #333;
}

#PaymentPage .ant-card ul {
  list-style-type: none;
  padding: 0;
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

#PaymentPage .ant-card ul li {
  margin: 10px 0;
}

#PaymentPage .ant-card .ant-btn-primary {
  background-color: #007bff;
  border: none;
  width: 100%;
  height: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 50px;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

#PaymentPage .ant-card .ant-btn-primary:hover {
  background-color: #0056b3;
}

#PaymentPage .ant-card-body {
  margin-top: 0;
  padding: 24px 20px !important;
}

#PaymentPage .ant-col {
  margin-bottom: 24px;
}

#PaymentPage .price-description {
  background: #f9f9f9;
  padding: 20px 0;
  width: 100%;
}

#PaymentPage .price-description h1 {
  font-size: 3rem;
  font-weight: 700;
  color: #007bff !important;
}

#PaymentPage .featured-plan .ant-card {
  transform: scale(1.1);
  z-index: 10;
  border: 2px solid #007bff;
}

#PaymentPage .featured-plan .ant-card .ant-card-body {
  padding: 30px 20px !important;
}

#PaymentPage .standard-plan {
  margin-top: 30px;
}

#PaymentPage .signup-button {
  width: 80%;
  margin: 20px auto 0;
  opacity: 0.9;
}

#PaymentPage .signup-button:hover {
  opacity: 1;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  #PaymentPage .ant-card {
    width: 100%;
  }

  #PaymentPage .featured-plan .ant-card {
    transform: scale(1);
  }
  #PaymentPage .standard-plan {
    margin-top: 0;
  }
  #PaymentPage .ant-card h2 {
    font-size: 2rem;
  }

  #PaymentPage .ant-card p {
    font-size: 1rem;
  }

  #PaymentPage .price-description h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  #PaymentPage .standard-plan {
    margin-top: 0;
  }
  #PaymentPage .ant-card {
    height: 500px;
  }

  #PaymentPage .ant-card h2 {
    font-size: 1.5rem;
  }

  #PaymentPage .ant-card p {
    font-size: 0.9rem;
  }

  #PaymentPage .price-description h1 {
    font-size: 2rem;
  }

  #PaymentPage .signup-button {
    width: 90%;
  }
}

@media (max-width: 630px) {
  #PaymentPage .standard-plan {
    margin-top: 0;
  }
  #PaymentPage .ant-card {
    height: fit-content;
  }

  #PaymentPage .ant-card h2 {
    font-size: 1.2rem;
  }

  #PaymentPage .ant-card p {
    font-size: 0.8rem;
  }

  #PaymentPage .price-description h1 {
    font-size: 1.5rem;
  }

  #PaymentPage .signup-button {
    width: 95%;
  }
}
