#Login {
  max-width: 350px;
  margin-left: 150px;
  max-height: 350px;
}

#Login .login-form {
  padding: 3rem;
  border-radius: 5px;
  position: relative;
  background-color: var(--light-color);
  z-index: 10;
}

#Login .form-item {
  text-align: justify;
}
#Login h1 {
  margin-bottom: 15px;
  padding: 0;
  color: var(--secondary-color);
}
#Login Input {
  width: 100%;

  padding-top: 5px;
  padding-bottom: 5px;
}

#Login .login-form-forgot {
  color: var(--light-color);
  margin-left: 0;
}

#Login .register {
  color: var(--light-color);
}

#Login .login-form-button {
  width: 100%;
  padding: 10px;
}

@media (max-width: 600px) {
  #Login {
    margin-left: 0;
  }
}
