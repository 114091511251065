.attention-tag {
  background-color: #ffe58f; /* Yellow background */
  color: #fa8c16; /* Orange text */
  font-weight: bold;
  font-size: 0.6rem; /* Half the original font size */
  padding: 5px 10px; /* Reduced padding */
  border-radius: 10px; /* Adjusted rounded corners */
  display: inline-block;
  text-align: center;
  box-shadow: 0 0 5px #fa8c16; /* Smaller glow */
  animation: pulse 1.5s infinite;
  transition: transform 0.2s ease-in-out;
  max-width: 90%; /* Prevent overflow */
  word-wrap: break-word; /* Allow wrapping of long words */
  white-space: normal; /* Wrap text */
  line-height: 1.3; /* Adjusted line spacing */
  margin: 0 auto;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 5px #fa8c16;
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 10px #ff9c6e;
  }
}

@media (max-width: 768px) {
  .attention-tag {
    font-size: 0.5rem; /* Smaller font on tablets */
    padding: 4px 8px;
  }
}

@media (max-width: 480px) {
  .attention-tag {
    font-size: 0.45rem; /* Even smaller font on mobile */
    padding: 3px 6px;
  }
}
