.announcement-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.announcement-date {
  color: gray;
  font-size: 12px;
  margin-left: 10px;
}
