/* Body */
#LandingPage #articles {
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.5;
  background: #1c2333;
}

/* Links */
/* #LandingPage a {
  color: var(--light-color);
  text-decoration: none;
} */

/* Unordered Lists */
#LandingPage ul {
  list-style: none;
}

/* Paragraphs */
#LandingPage p {
  margin: 0.5rem 0;
  line-height: 1.7;
}

/* Images */
#LandingPage img {
  width: 100%;
}

/* Headings */
#LandingPage h1,
#LandingPage h2,
#LandingPage h3,
#LandingPage h4,
#LandingPage h5,
#LandingPage h6 {
  margin-bottom: 0.55rem;
  line-height: 1.3;
  color: var(--primary-color);
}

/* Utilities */
#LandingPage .container {
  max-width: var(--max-width);
  margin: auto;
  padding: 0 2rem;
  overflow: hidden;
}

#LandingPage .card {
  display: grid;
  grid-template-rows: 2fr 1fr;
  /* grid-template-rows: minmax(200px, 200px) 1fr; */
  gap: 1rem;
  background: var(--dark-color);
  border: var(--dark-color-1) 1px solid;
  padding: 1rem;
  border-radius: 10px;
}

#LandingPage .card:hover {
  border-color: var(--dark-color-2);
}

#LandingPage .card img {
  text-align: center;
  border-radius: 3px;
  max-height: 100%;
  min-height: 100%;
  object-fit: cover;
  margin: auto;
}

#LandingPage .card > div {
  margin: auto;
}

#LandingPage .card-content {
  border-radius: 20px;
  border: 2px solid #f4f4f4;
  padding: 15px;
  margin: 20px auto;
  text-align: center;
  width: 90%;
}

#LandingPage a {
  color: var(--light-color);
}

#LandingPage .btn {
  cursor: pointer;
  display: inline-block;
  color: var(--light-color);
  background: var(--primary-color-1);
  padding: 5px 20px;
  border-radius: 5px;
  border: 5px;
}

#LandingPage .btn:hover {
  opacity: 0.9;
}

#LandingPage .btn-menu {
  padding: 5px;
  border-radius: 5px;
}

#LandingPage .btn-menu:hover {
  color: var(--light-color);
  background: var(--dark-color);
}

#LandingPage .manu-link {
  padding: var(--gap-8);
}

#LandingPage .manu-link:hover {
  color: var(--light-color);
}

#LandingPage .py-2 {
  padding: 20px 0;
}

#LandingPage .f-width {
  width: 100%;
}

#LandingPage .l-heading {
  font-size: 3rem;
}

#LandingPage .hidden {
  display: none !important;
}

#LandingPage .l-text {
  font-size: 20px;
}

#LandingPage .justify-text {
  text-align: justify;
}

#LandingPage .text-primary {
  font-weight: bold;
  color: var(--primary-bg-color);
}

#LandingPage .auth-ul .btn .text-secondary {
  color: var(--secondary-color);
}

#LandingPage .auth-ul .btn {
  width: 100%;
  font-size: medium;
  text-align: center;
  padding: 5px 20px;
  min-height: 1rem;
}

/* Navigation */
#LandingPage #main-nav {
  position: sticky;
  top: 0;
  left: 0;
  height: 60px;
  background: var(--dark-color);
  z-index: 2;
}

#LandingPage #main-nav .main-nav-container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding-left: 10px;
  padding-right: 15px;
  grid-auto-flow: column;
  grid-column-gap: var(--gap-8);
  height: 100%;
}

#LandingPage #main-nav .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#LandingPage #main-nav .logo-container:hover {
  cursor: pointer;
}

#LandingPage #main-nav .logo {
  font-size: larger;
  font-family: "Tektur", sans-serif;
  font-weight: 900;
  margin-bottom: 0;
}

#LandingPage #main-nav .main-nav-container .logo-container img {
  max-width: 30px;
  margin-right: 5px;
}

#LandingPage #main-nav .main-menu ul {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
}

#LandingPage #main-nav ul a {
  padding: 0;
  margin: 0;
}

#LandingPage #main-nav .auth-container ul {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
}

#LandingPage #main-nav li {
  margin: 0 5px;
}

#LandingPage #main-nav .menu-btn-container {
  display: none;
}

#LandingPage #main-nav .side-nav {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--dark-color-3);
  border-radius: var(--gap-8);
  margin-right: 10px;
  margin-top: 5px;
  padding: 10px 0;
  display: none;
}

#LandingPage #main-nav .side-nav li {
  display: flex;
  padding: 10px;
  text-align: center;
}

/* Showcase */
#LandingPage #showcase {
  color: var(--color-secondary);
  background: var(--dark-color);
  padding: 2rem;
  position: relative;
}

#LandingPage #showcase:before {
  content: "";
  background: url("../../../../public/images/showcase.jpg") no-repeat center
    center/cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

#LandingPage #showcase .showcase-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-content: center;
  align-items: center;
  height: 82vh;
}

/* Showcase */
#LandingPage #showcase .showcase-content {
  z-index: 1;
}

#LandingPage #showcase .showcase-content h1 {
  text-align: left;

  color: var(--primary-color);
}

#LandingPage #showcase .showcase-content p {
  text-align: left;
  margin-bottom: 1rem;
  color: #fff;
}

#LandingPage #showcase .showcase-content .btn {
  padding: 10px;
}

/* Main */
#LandingPage #articles .articles-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-8);
  padding: 30px 0;
  color: #fff;
}

#LandingPage #articles .articles-container span {
  font-size: 15px;
  margin-bottom: 10px;
}

#LandingPage #articles .articles-container .card {
  grid-template-rows: 1fr 1fr;
}

#LandingPage #articles .articles-container .card div {
  max-height: 250px;
  width: 100%;
  overflow: hidden;
}

#LandingPage #articles .articles-container .card img {
  width: 100%;
  height: 250px;
}

/* Login Page */

/* Showcase */
#LandingPage #login .showcase:before {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #0e1525;
  opacity: 0.06;
}

#LandingPage #login .main-nav .main-nav-container {
  max-width: 95%;
}

#LandingPage #login .showcase .container {
  max-width: 100%;
}

#LandingPage #login .showcase .showcase-container {
  width: 100%;
}

#LandingPage #login .showcase h1 {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}

#LandingPage #login .login-form {
  position: relative;
  display: flex;
  max-width: 400px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#LandingPage #login .login-form > * {
  z-index: 1;
}

#LandingPage #login .form-group {
  margin-bottom: 5px;
}

#LandingPage #login .login-form form input {
  width: 100%;
  display: block;
  padding: 10px;
  background: #1c2333;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1rem;
}

#LandingPage #login .form-group input[type="email"] {
  border: 1px solid #4e5569;
}

#LandingPage #login .form-group input[type="email"]:focus {
  border: 1px solid #0053a6;
}

#LandingPage #login .login-form form input:focus {
  outline: none;
}

/* Password Input Container */
#LandingPage #login .login-form form .password-input-container {
  background-color: #1c2333;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #4e5569;
}

#LandingPage #login .login-form form .password-input-container:focus-within {
  border: 1px solid #0053a6;
}

#LandingPage #login .login-form form .password-input-container input {
  height: 100%;
}

#LandingPage #login .login-form form button {
  width: 100%;
  padding: 10px;
}
/* Login Page */

/* Password Input Container */
#LandingPage #login .login-form form .password-input-container .btn-pass-show {
  display: inline-block;
  height: 100%;
  background: transparent;
  border: none;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  justify-self: flex-end;
  flex-basis: 20%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 100;
  font-family: var(--default-font-family);
}

#LandingPage #login .login-form form .password-input-container div:first-child {
  flex-basis: 80%;
}

/* Footer */
#LandingPage #main-footer {
  background: var(--dark-color);
  color: #f4f4f4;
  padding: 20px 0;
}

#LandingPage #main-footer img {
  width: 60px;
}

#LandingPage #main-footer .footer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

#LandingPage #main-footer .footer-container p,
#LandingPage #main-footer .footer-container a {
  font-size: 14px;
}

/* General Responsive Styles */
@media (max-width: 768px) {
  #LandingPage .container {
    padding: 0 1rem;
  }

  #LandingPage .main-menu ul {
    display: none; /* Hide menu links for smaller screens */
  }

  #LandingPage .menu-btn-container {
    display: block; /* Show hamburger menu for smaller screens */
  }

  #LandingPage #showcase .showcase-container {
    grid-template-columns: 1fr;
    text-align: center; /* Stack content vertically */
  }

  #LandingPage #articles .articles-container {
    grid-template-columns: 1fr; /* Single column for articles */
  }

  #LandingPage #main-footer .footer-container {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  #LandingPage #showcase .showcase-container {
    grid-template-columns: 1fr 1fr; /* Adjust columns for tablets */
  }

  #LandingPage #articles .articles-container {
    grid-template-columns: 1fr 1fr; /* Two-column grid for articles */
  }
}

@media (max-width: 576px) {
  #LandingPage #main-footer .footer-container {
    grid-template-columns: 1fr;
    text-align: left;
  }

  #LandingPage #main-footer .footer-container div {
    margin-bottom: 20px;
  }

  #LandingPage #main-footer .footer-container img {
    margin: 0 auto 15px;
  }
}

@media (max-width: 480px) {
  #LandingPage .l-heading {
    font-size: 2rem; /* Adjust heading size for small screens */
  }

  #LandingPage .l-text {
    font-size: 16px; /* Adjust text size for small screens */
  }

  #LandingPage #showcase {
    padding: 1rem; /* Reduce padding for small screens */
  }

  #LandingPage .btn {
    padding: 8px 16px; /* Adjust button padding */
  }

  #LandingPage .footer-container {
    gap: 1rem; /* Reduce gap for footer items */
  }
}
