.breadcrumb {
  list-style: none;
  display: flex;
}
.breadcrumb .link {
  color: var(--blue-color);
}
.breadcrumb li {
  margin-right: 5px;
  color: var(--blue-color);
}

.breadcrumb li::after {
  content: ">";
  margin-left: 5px;
  color: var(--blue-color);
}

.breadcrumb li:last-child::after {
  content: "";
}
