.receipt-container {
  width: 100%;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.store-info,
.receipt-header,
.customer-info,
.payment-info,
.voucher-info {
  margin-bottom: 20px;
}

.store-info h2,
.receipt-header h3 {
  text-align: center;
  margin: 0;
}

.store-info p,
.receipt-header p,
.customer-info p,
.payment-info p,
.voucher-info p {
  margin: 5px 0;
}

.receipt-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.receipt-table th,
.receipt-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.receipt-table th {
  background-color: #f2f2f2;
}

.summary p {
  text-align: right;
  margin: 5px 0;
}

.summary p strong {
  font-size: 16px;
}
