/* src/components/Assessment.css */

.assessment-page {
  padding: 20px;
}

.ant-select {
  width: 200px;
}

.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  color: white;
}

.ant-btn-primary:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.ant-modal-title {
  text-align: center;
  font-size: 24px;
}

.ant-form-item-label > label {
  font-weight: bold;
}

#Assessment h2 {
  margin-left: 25px;
}
