#Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  line-height: 1;
}

#Header .schoolName {
  font-size: large;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left:hover {
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.logo span {
  color: white;
}

.logo span:hover {
  cursor: pointer;
}

.logo-img {
  width: 40px;
  object-fit: contain;
  margin-right: 10px;
}

@media (max-width: 650px) {
  #Header .schoolName {
    font-size: small !important;
  }
}
@media (max-width: 500px) {
  #Header .schoolName {
    font-size: x-small !important;
  }
}
