#SchoolCode {
  max-width: 350px;
  max-height: 350px;
}

#SchoolCode .school-code-form {
  padding: 3rem;
  border-radius: 5px;
  position: relative;
  background-color: var(--light-color);
  z-index: 10;
}

#SchoolCode .form-item {
  text-align: justify;
}
#SchoolCode h1 {
  margin-bottom: 15px;
  padding: 0;
  color: var(--secondary-color);
}
#SchoolCode Input {
  width: 100%;
  /* padding: 4px 14px; */
}

#SchoolCode .login-form-forgot {
  color: var(--light-color);
  margin-left: 0;
}

#SchoolCode .register {
  color: var(--light-color);
}

#SchoolCode .login-form-button {
  width: 100%;
  padding: 10px;
}

@media (max-width: 700px) {
  #SchoolCode {
    margin-left: 150px;
  }
}
@media (max-width: 600px) {
  #SchoolCode {
    margin-left: 0;
  }
}
