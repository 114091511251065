#SchoolProfile .school-profile {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  border-radius: 8px;
  background-color: #fff;
}

#SchoolProfile .school-header {
  text-align: center;
  margin-bottom: 20px;
}

#SchoolProfile .school-logo {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

#SchoolProfile .school-name {
  font-size: 2em;
  margin: 0;
}

#SchoolProfile .school-motto {
  font-style: italic;
  color: #555;
}

#SchoolProfile .school-details,
#SchoolProfile .contact-info,
#SchoolProfile .overview,
#SchoolProfile .social-media {
  margin-bottom: 20px;
}

#SchoolProfile h2 {
  border-bottom: 2px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

#SchoolProfile .profile-card {
  width: 95%;
  margin: auto;
}

#SchoolProfile p {
  line-height: 1.6;
}

#SchoolProfile a {
  color: #007bff;
  text-decoration: none;
}

#SchoolProfile a:hover {
  text-decoration: underline;
}
